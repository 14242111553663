<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">消息管理</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item>消息发布</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="card-box">
      <div class="screen-box">
        <div class="input-box">
          <div class="title-box">消息标题:</div>
          <el-input placeholder="请输入消息标题"></el-input>
        </div>
        <div class="input-box">
          <div class="title-box">状态:</div>
          <el-select v-model="value1" filterable
              clearable placeholder="请选择阅读状态">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <button class="btn-pre"><i class="fa fa-search"></i>查询</button>
        <button class="btn-add" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建公告
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          border
          height="500"
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="消息标题" width="120">
          </el-table-column>
          <el-table-column prop="Enable" label="阅读状态" width="80">
            <template slot-scope="scope">
              <div class="label-success approval" v-if="scope.row.state === 1">
                已读
              </div>
              <div class="label-warning approval" v-if="scope.row.state === 2">
                未读
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="time" label="时间" width="170">
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <i class="fa fa-search" @click="noticeDrawer = true"></i>
              <i class="fa fa-trash" v-if="powers.delete"></i>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="getWorkflows(1)"
          @current-change="getWorkflows"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 公告详情 -->

    <el-dialog
      title="公告详情"
      :visible.sync="noticeDrawer"
      width="800px"
      :before-close="handleNoticeClose"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="add-common">
          <div class="content">
            <div class="title">标题: XX</div>
            <div class="time-box">
              <span>发布于2024/01/08</span>
              <span>14:00</span>
            </div>
            <div class="html" v-html="html"></div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleNoticeClose">取消</el-button
        ><el-button class="pop-save" @click="handleNoticeClose">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 1,
      value1: null,
      size: 10,
      total: 2,
      page: 1,
      tableData: [
        {
          title: "这是一个标题",
          state: 1,
          time: "2024-02-20",
        },
        {
          title: "这是一个标题",
          state: 2,
          time: "2024-02-20",
        },
      ],
      options1: [
        {
          value: 1,
          label: "已读",
        },
        {
          value: 2,
          label: "未读",
        },
      ],
      messageList: [
        {
          name: "您有一个新公告《XXX》,请及时查看",
          title: "公告提醒",
          time: "18小时前",
        },
        {
          name: "您有一个新公告《XXX》,请及时查看",
          title: "公告提醒",
          time: "18小时前",
        },
      ],
      direction: "rtl",
      noticeDrawer: false,
      html: "我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容 我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容 我是内容我是内容我是内容我是内容我是内容我是内容我是内容我是内容",
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
    };
  },
  methods: {
    deleteItem(index) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.messageList.splice(index, 1);
        this.$message.success("删除成功");
      });
    },
    getWorkflows() {},
    handleNoticeClose() {
      this.noticeDrawer = false;
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }
  

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;
      

      actions.forEach((element) => {
        

        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
  },
  created() {
    this.getButtonPower();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px 0;
    .screen-box {
      display: flex;
      align-items: center;
      padding: 0 12px;
      .el-input {
        width: 150px;
        height: 34px;
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      button {
        margin-left: 24px;
        border: none;
        padding: none;
        padding: 6px 12px;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        .fa {
          color: #fff;
          margin: 0;
          margin-right: 5px;
        }
      }
      .btn-pre {
        background-color: #3f729b;
      }
      .btn-pre:hover {
        background-color: #305777;
      }
      .btn-add {
        background-color: #3c8dbc;
      }
      .btn-add:hover {
        background-color: #367fa9;
      }
      .input-box {
        display: flex;
        align-items: center;
        height: 34px;
        .title-box {
          padding: 0 12px;
          border: 1px solid #d2d6de;
          line-height: 32px;
          border-right: none;
          color: #555;
          font-size: 14px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 65px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
    }
  }
  .dialog-main {
    border-bottom: 2px solid #e6e6e6;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
  }
  .dialog-footer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add-common {
    width: 100%;
    height: 100%;
    padding: 0 20px 10px 20px;
    box-sizing: border-box;
    .content {
      .title {
        font-size: 14px;
        color: #333333;
        margin-top: 0px;
      }
      .time-box {
        font-size: 12px;
        color: #999999;
        margin-top: 18px;
        margin-bottom: 18px;
        span {
          margin-right: 30px;
        }
      }
      .html {
        color: #333333;
        font-size: 14px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    font-weight: normal;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
</style>